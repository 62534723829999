.loginBackground {
  background-image: url('https://www.agcs.allianz.com/news-and-insights/expert-risk-articles/construction-claims-trends/_jcr_content/root/stage/stageimage.img.82.3360.jpeg/1666955393504/agcs-grd-autumn-winter-2022-construction-claims-trends-stage.jpeg');
  background-position: center;
  background-repeat: no-repeat;

  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}


.download-table-xls-button {
  /* Example styles */
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-color: #226e3f;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius:3px;
  height:50px;
}

.download-table-xls-button:hover {
  background-color: #0056b3;
}


.customerBackground {
  background-image: url('https://teja12.kuikr.com/is/a/c/1200x500/gallery_images/original/cf6100def759bdc.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 71px;
  right: 71px;
  top: 0;
}

.navbar2 {
  background: rgb(224, 213, 249);
  background: linear-gradient(315deg, rgb(231, 233, 252) 0%, rgb(86, 63, 146) 100%);
  height: 2rem;
}

.navbar3 {
  background: rgb(126, 110, 228);
  background: linear-gradient(315deg, rgb(126, 110, 228) 0%, rgb(126, 110, 228) 100%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.navbar1 {
  background: rgb(180, 233, 252);
  background: linear-gradient(315deg, rgba(180, 233, 252, 1) 0%, rgba(198, 237, 251, 0.559983368347339) 100%);
}

.login-main-container {
  margin: 0;
}

.auth-form-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.navigation-container {
  box-shadow: rgb(199, 192, 255) 0px 2px 2px;
  background-color: white;
}

.sidebar-container{
  box-shadow: rgb(199, 192, 255) 0px 2px 2px;
  border: none;
}

.main-container{
  background-color: rgb(249, 249, 254);
  padding-bottom: 4px;
}

 
.topCard,.leadManagementTable{
  box-shadow: rgb(216, 211, 254) 1px 1px 1.4px;
  padding: 1px;
  color: rgb(179, 238, 179);
}

.leadManagementTable1{
  border: 3px solid rgb(126, 110, 228);
}

.customerContainer{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.customizescrollbar::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.customizescrollbar::-webkit-scrollbar-button {
  display: none;
}

.customizescrollbar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgba(255,255,255,.4);
}

.customizescrollbar::-webkit-scrollbar-track {
  border-radius: 50px;
}

.customizescrollbar::-webkit-scrollbar {
  display: none;
}

.customizescrollbar:hover::-webkit-scrollbar {
  display: block;
}

body{
  background-color: rgb(216, 211, 251);
}

 