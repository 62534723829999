/* main */
.main-container{
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }

  .main-title{
    display: flex;
    justify-content: space-between;
  }

  .main-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }

  .card-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .card-box:first-child{
    background-color: rgb(172, 187, 35);
  }

  .card-box:nth-child(2){
    background-color: rgb(107, 196, 34);
  }
  .card-box:nth-child(3){
    background-color: green;
  }
  .card-box:nth-child(4){
    background-color: rgb(39, 41, 39);
  }
  .card-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-inner > .card-icon {
    font-size: 25px;
  }

  .charts{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }

 /* end main */