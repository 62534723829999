
.custom-alert {
    z-index: 9999; 
  }
  
  .custom-alert .swal2-popup {
    background-color: #f8d7da; 
    color: #721c24; 
    border: 1px solid #f5c6cb; 
  }
  
  .custom-alert .swal2-success {
    background-color: #d4edda; 
    color: #155724; 
    border: 1px solid #c3e6cb; 
  }
  .swal2-container {
    z-index: 9999; 
  }